// Libraries
import React, { useContext, useState, useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Box, Pagination, Stack } from '@mui/material';

// Local
import { PAGINATED_SEARCH } from '../../Queries/nlp';
import { DocumentContext } from '../../DocumentContext';
import SearchMenu from './SearchMenu';
import SearchBox from './SearchBox';
import SearchResult from './SearchResult';
import SearchIdle from './SearchIdle';
import Empty from '../Library/Empty';
import Offline from '../Library/Offline';
import Item from '../Library/Item';
import { addScoresToSearchResults } from '../../Utilities/addScoresToSearchResults';

const Search = ({ client, user }) => {
  const context = useContext(DocumentContext);
  const [height, setHeight] = useState(0);

  const heightRef = useRef(null);
  const searchResultsRef = useRef();

  const [paginatedSearch] = useLazyQuery(PAGINATED_SEARCH, {
    fetchPolicy: 'network-only',
  });

  const handlePageClick = async (page) => {
    context.updateContext('loadingRequest', true);
    context.updateContext('loadingMessage', 'Retrieving papers');

    // i.e. page 1 x 20 = 20, 20 - 10 = 10. Begin from 10th element.
    const from = page * 10 - 10;
    // i.e. page 1 x 20 = 20 - 1. Slice until 19th element. 99 elements as index
    const to = page * 10;

    let paperList = context.paperIds.slice(from, to);

    paperList = paperList.map(
      ({
        collection,
        id_field: idField,
        id_type: idType,
        id_value: idValue,
      }) => {
        return {
          collection,
          id_field: idField,
          id_type: idType,
          id_value: idValue,
        };
      }
    );

    paginatedSearch({
      variables: {
        paper_list: paperList,
        keywords: context.keywords,
      },
      onCompleted: ({ paginatedSearch }) => {
        if (paginatedSearch.status === 'success') {
          const updatedSearchResults = addScoresToSearchResults(
            paginatedSearch.response, // in batches of 10 papers
            context.rerankingScores, // reranking scores for all papers
            context.prefetchingScores, // prefetching scores for all papers
            page, // current page
            10 // items per page
          );

          context.updateContext('searchResults', updatedSearchResults);

          context.updateContext('currentPage', page);
          searchResultsRef.current.scrollTo(0, 0);

          context.updateContext('loadingRequest', false);
          context.updateContext('loadingMessage', 'Retrieved papers');
        } else {
          context.updateContext('loadingRequest', false);
          context.updateContext('loadingMessage', '');
        }
      },
    });
  };

  useEffect(() => {
    if (!heightRef.current) return; // wait for the heightRef to be available
    const resizeObserver = new ResizeObserver(() => {
      // Do what you want to do when the size of the element changes
      setHeight(heightRef.current.clientHeight);
    });
    resizeObserver.observe(heightRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  useEffect(() => {
    searchResultsRef.current.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [context.searchResults]);

  return (
    <Box
      sx={{
        display: context.tab !== 'Discover' && 'none',
        minHeight: '91vh',
      }}
    >
      <SearchMenu client={client} />
      <SearchBox client={client} heightRef={heightRef} />
      <Box
        ref={searchResultsRef}
        sx={{
          overflowY: 'auto',
          display:
            context.searchLibrary || context.onlyManuscriptLibrary
              ? 'none'
              : 'block',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? 'background.paper' : '#171717',
          height: {
            xs: `calc(100vh - 31px - 41px - ${height}px)`,
            sm: `calc(100vh - 31px - 41px - ${height}px - 35px)`,
          },
        }}
      >
        {context.searchResults.length === 0 ? (
          <SearchIdle />
        ) : (
          context.searchResults.map((result) => (
            <SearchResult
              key={result._id}
              client={client}
              paper={result}
              user={user}
              modular={false}
            />
          ))
        )}
        <Stack
          spacing={2}
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: context.searchResults.length <= 9 && 'none',
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <Pagination
            color="primary"
            count={Math.floor(context.pageCount)} // total number of pages
            page={context.currentPage} // the current page
            onChange={(_, page) => handlePageClick(page)}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </Box>
      <Box
        sx={{
          overflowY: 'auto',
          display:
            !context.searchLibrary || !context.onlyManuscriptLibrary
              ? 'block'
              : 'none',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? 'background.paper' : '#171717',
          height: {
            xs: `calc(100vh - 31px - 41px - ${height}px)`,
            sm: `calc(100vh - 31px - 41px - ${height}px - 35px)`,
          },
        }}
      >
        {context.bookmarks.length >= 1 ? (
          context.bookmarks
            .filter((bookmark) => {
              if (context.onlyManuscriptLibrary) {
                return context.manuscriptLibrary.some(
                  (libraryItem) => libraryItem._id === bookmark._id
                );
              } else {
                return true;
              }
            })
            .map((bookmark, index) => (
              <Item
                key={index}
                paper={bookmark}
                client={client}
                modular={false}
              />
            ))
        ) : context.serviceWarnings.content === '' ? (
          <Empty />
        ) : (
          <Offline />
        )}
      </Box>
    </Box>
  );
};

export default Search;
